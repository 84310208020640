/* ProductList.css */

.product-list-container {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between; 
  }
  
  .product-cards {
    display: flex;
    flex-wrap: wrap; 
  }
  