.edit-product-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.edit-product-form {
  display: flex;
  flex-direction: column;
}

.edit-product-form label {
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
}

.edit-product-form input[type="text"],
.edit-product-form input[type="number"],
.edit-product-form textarea {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 15px;
}

.edit-product-form button {
  padding: 10px 15px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.edit-product-form button:hover {
  background-color: #0056b3;
}

.edit-product-form button[type="button"] {
  font-size: 18px;
  margin-top: 10px;
  background-color: rgb(235, 179, 24);
}

