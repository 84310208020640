/* ProductCard.css */

.product-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  transition: transform 0.2s;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-image img {
  max-width: 200px;
  height: 200px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.product-details h3 {
  font-size: 1.5rem;
  margin: 0;
}

.product-details p {
  margin: 5px 0;
  font-size: 1rem;
  color: #333;
}

.product-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.product-actions button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s;
}

.product-actions button.edit-button {
  background-color: #007bff;
}

.product-actions button.edit-button:hover {
  background-color: #0056b3;
}

.product-actions button.delete-button {
  background-color: #dc3545;
}

.product-actions button.delete-button:hover {
  background-color: #c82333;
}
